.appBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 50px 0;
  align-items: center;
  background-color: #070f24;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: calc(100% - 100px);
  border-bottom: #eee solid 2px;
}
@media (max-width: 1000px) {
  .appBar {
    width: calc(100% - 50px);
    padding: 0 25px 0;
  }
}
.appBar p {
  color: #eee;
  font-size: 25px;
  font-weight: 500;
}
@media (max-width: 1000px) {
  .appBar p {
    font-size: 21px;
  }
}
.appBar .largeDiv {
  min-width: 550px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1000px) {
  .appBar .largeDiv {
    display: none;
  }
}

.appBar .largeDiv span {
  font-size: 23px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.appBar .largeDiv span img{
  width: 20px;
}
.appBar .large {
  list-style: none;
  display: flex;
  justify-content: space-between;
  min-width: 500px;
  font-size: 20px;
}


.appBar .large a {
  text-decoration: none;
  color: #eee;
  font-weight: 400;
  display: block;
}
.appBar .large li::after {
  content: "";
  display: block;
  height: 2px;
  background-color: #eee;
  width: 0%;
  transition: 0.3s ease-out;
  margin-left: 50%; 
  transform: translateX(-50%); 
}
.appBar ul li:hover::after {
  width: 100%;
}
.appBar .small {
  
  list-style: none;
  background-color: #051636;
  position: absolute;
  right:  24px;
  top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:20px 20px;
  border-radius: 10px 0 10px 10px;
  border: 1px #eee solid;
  width: 150px;
  display: none;
}
.appBar .apear:hover .small {
  display: flex;
}
.appBar .apear .smallMenuBLog {
  width: 100%;
  text-align: center;
}
.appBar .small hr {
  width: 100%;
}
.appBar .small li {
  margin-bottom: 10px;
}
.appBar ul a, .appBar .small span {

  text-decoration: none;
  color: #eee;
  font-weight: 400;
}

.appBar .icon {
  height: 20px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  display: none;
}
@media (max-width: 1000px) {
  .appBar .icon {
    display: flex;
  }
}
.appBar .icon .line {
  height: 2px;
  width: 100%;
  background-color: #eee;
}
