.projectsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media (max-width: 1000px) {
  .projectsRow {
    flex-direction: column;
    align-items: center;
  }
}
.showMoreButtonProjects {
  text-decoration: none;
  color: #eee;
  font-size: 20px;
  border-bottom: #eee solid 2px;
  width: min-content;
  cursor: pointer;
}
