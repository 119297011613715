html {
  scroll-behavior: smooth;
}
html[lang="ar"] {
  direction: rtl;
  text-align: right;
}
html[lang="ar"] .appBar {
  direction: ltr;
}
html[lang="ar"] .appBar .large {
  direction: rtl;
}
html[lang="ar"] .appBar .large li::after {
  margin-right: 50%;
  transform: translateX(50%);
}
html[lang="en"] .blogLi,
html[lang="en"] .blogTitle,
html[lang="en"] .articles,
html[lang="en"] .showMoreButtonArticles,
html[lang="en"] .smallMenuBLog {
  display: none;
}
html[lang="en"] .appBar .large {
  min-width: 450px;
}
html[lang="ar"] .row .landingText {
  direction: rtl;
}
html[lang="ar"] .about {
  text-align: right;
  direction: rtl;
}
html[lang="ar"] .about .aboutEn {
  display: none;
}
html[lang="en"] .about .aboutAr {
  display: none;
}
html[lang="ar"] .contact p {
  direction: ltr;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070f24;
}

/* تخصيص الشريط في WebKit */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #eee;
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb {
  background: #051636;
  /* border-radius: 10px; */
}

::-webkit-scrollbar-thumb:hover {
  background: #070f24;
}

/* تخصيص الشريط في Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: #051636 #eee;
}
