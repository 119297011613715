.contact {
    background-color: #051636;
    font-weight: 500;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 50px;
    color: #eee;
    font-size: 19px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4) ;
}
@media (max-width: 1000px) {
    .contact {
        font-size: 18px;
    }
}
.contact .contactRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    padding: 5px;
    border-radius: 5px;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
.contact .contactRow:hover {
    background-color: rgba(0, 0, 0, 0.4);
    scale: 1.03;
    color: blue;
}
.contact .contactRow img {
    height: 30px;
}