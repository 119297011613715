.project {
    background-color: #051636;
    width: 20%;
    padding: 10px;
    border-radius: 10px;
    color: #eee;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}
@media (max-width: 1000px) {
    .project {
        margin-bottom: 40px;
        width: 80%;
    }
}
.project img {
    width: 100%;
}
.project p {
    max-width: 100%;
}

.project {
    transition: 0.3s ease-in-out;
  }
  /* .project:hover {
    scale: 1.1;
  } */
  