.service {
    background-color: #051636;
    width: 20%;
    padding: 10px;
    border-radius: 10px;
    color: #eee;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
}
@media (max-width: 1000px) {
    .service {
        margin-bottom: 30px;
        width: 80%;
    }
}
.service img {
    width: 100%;
}
.service p {
    max-width: 100%;
}

  