.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 130px 0 0;
  }
  @media (max-width: 1000px) {
    .row {
      padding: 70px 0 0;
      flex-direction: column;
      align-items: center;
    }
  }

.row img {
    width: 400px;
}
@media (max-width: 1000px) {
    .row img {
      width: 100%;
    }
  }
.row p {
    font-size: 20px;
    max-width: 500px;
    line-height: 50px;
    color: #eee;
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto;
  }
  @media (max-width: 1000px) {
    .row p {
      max-width: 100%;
      font-size: 18px;
      padding: 0 20px;
      line-height: 35px;
    }
  }